import {StoryblokComponent} from '@storyblok/react'
import ButtonBlockContainer from 'components/shared/ButtonBlockContainer'
import React from 'react'
import {ColumnsBlockStoryblok} from 'types/storyblok-types'
import TextBlockContainer from './Text'
import HeaderContainer from './Header'

type ColumnsProps = {
  blok: ColumnsBlockStoryblok
}
const Columns = ({blok}: ColumnsProps) => {
  return (
    <div className="grid grid-cols-1 gap-[39px]  md:gap-10 lg:grid-cols-2">
      <div className="flex flex-col items-start lg:pb-0">
        {blok.column_1
          ? blok.column_1.map((blok, idx) => {
              switch (blok.component) {
                case 'header':
                  if (idx > 0) {
                    return (
                      <HeaderContainer
                        key={blok._uid}
                        blok={blok}
                        withoutBottomMargin={true}
                        includeTopMargin={true}
                      />
                    )
                  } else {
                    return (
                      <HeaderContainer
                        key={blok._uid}
                        blok={blok}
                        withoutBottomMargin={true}
                      />
                    )
                  }

                case 'button':
                  return (
                    <ButtonBlockContainer
                      key={blok._uid}
                      blok={blok}
                      topMargin={8}
                    />
                  )

                case 'text':
                  return (
                    <TextBlockContainer
                      key={blok._uid}
                      blok={blok}
                      topMargin={8}
                    />
                  )

                default:
                  return <StoryblokComponent key={blok._uid} blok={blok} />
              }
            })
          : null}
      </div>
      <div>
        {blok.column_2
          ? blok.column_2.map((blok, idx) => {
              switch (blok.component) {
                case 'header':
                  if (idx > 0) {
                    return (
                      <HeaderContainer
                        key={blok._uid}
                        blok={blok}
                        withoutBottomMargin={true}
                        includeTopMargin={true}
                      />
                    )
                  } else {
                    return (
                      <HeaderContainer
                        key={blok._uid}
                        blok={blok}
                        withoutBottomMargin={true}
                      />
                    )
                  }

                case 'button':
                  return (
                    <ButtonBlockContainer
                      key={blok._uid}
                      blok={blok}
                      topMargin={8}
                    />
                  )

                case 'text':
                  return (
                    <TextBlockContainer
                      key={blok._uid}
                      blok={blok}
                      topMargin={8}
                    />
                  )

                default:
                  return <StoryblokComponent key={blok._uid} blok={blok} />
              }
            })
          : null}
      </div>
    </div>
  )
}
export default Columns
