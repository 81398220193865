import React from 'react'
import {ButtonStoryblok} from 'types/storyblok-types'
import ButtonBlock from './ButtonBlock'
import {storyblokEditable} from '@storyblok/react'
import linkTypeChecker from 'util/linkTypeChecker'

type ButtonBlockContainerProps = {
  blok: ButtonStoryblok
  topMargin?: number
}
const ButtonBlockContainer = ({blok, topMargin}: ButtonBlockContainerProps) => {
  return (
    <div
      {...storyblokEditable(blok)}
      className={`${topMargin && `mt-${topMargin}`}`}
    >
      <ButtonBlock
        title={blok.button_label ?? ''}
        cta_url={linkTypeChecker(blok.link)}
        cta_target={blok.link?.target}
        styling={blok.button_color}
        type="button"
        className=""
      />
    </div>
  )
}
export default ButtonBlockContainer
